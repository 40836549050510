
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import ClienteCadastro from "@/views/pages/widgets/ClienteCadastro.vue";

export default defineComponent({
  name: "ClientesList",
  components: { ClienteCadastro },
  props: {
    clientes: Array
  },
  setup() {
    const router = useRouter();
    const cliente = ref<unknown>({});

    function setCliente(cli) {
      cliente.value = cli;
    }

    function criarLaudo(cliente, veiculo) {
      router.push({
        name: "Cadastrar-laudo",
        query: {
          nome: cliente.nome,
          marca: veiculo.marca,
          modelo: veiculo.modelo,
          veiculoId: veiculo.id
        }
      });
    }

    return {
      ClienteCadastro,
      criarLaudo,
      setCliente,
      cliente
    };
  }
});


import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { pt } from "yup-locale-pt";

export default defineComponent({
  name: "VeiculoCadastro",
  components: { Field, Form },
  props: {
    veiculo: Object,
    clienteId: String
  },
  setup(props) {
    const valoresIniciais = {
      id: null,
      marca: null,
      modelo: null,
      clienteId: null,
      quilometragem: null,
      anoFabricacao: null,
      anoModelo: null,
      renavam: null,
      placa: null,
      chassi: null
    }
    const cadastro = ref<any>(JSON.parse(JSON.stringify(valoresIniciais)));
    const veiculoProp = reactive<any>(props);
    const modal = ref<HTMLElement | null>(null);
    const closeModal = ref<HTMLButtonElement | null>(null);
    const isCadastroNovo = ref(true);
    const form = ref<HTMLFormElement>();

    Yup.setLocale(pt);
    const validacoes = Yup.object().shape({
      formMarca: Yup.string().nullable().required().label("Marca"),
      formModelo: Yup.string().required().label("Modelo"),
      formQuilimetragem: Yup.number().nullable().required().label("Quilometragem"),
      formAnofabricacao: Yup.number().nullable().required().label("Ano fabricação"),
      formAnomodelo: Yup.number().nullable().required().label("Ano modelo"),
      formPlaca: Yup.string().nullable().required().label("Placa"),
      formRenavam: Yup.string().nullable().required().label("Renavam"),
      formChassi: Yup.string().nullable().required().label("Chassi"),
    });

    onMounted(() => {
      modal.value?.addEventListener("hidden.bs.modal", () => {
        isCadastroNovo.value = true;
        cadastro.value = JSON.parse(JSON.stringify(valoresIniciais));
        form.value?.resetForm();
      });
    });

    watch(veiculoProp, () => {
      const veiculo = veiculoProp.veiculo;
      const clienteId = veiculoProp.clienteId;
      
      if (clienteId) {
        const merged = { ...cadastro.value, clienteId };
        cadastro.value = merged;
        console.log('cadastro.value', cadastro.value);
      }
      if (veiculo["id"]) { 
        isCadastroNovo.value = false;
        const merged = { ...cadastro.value, ...veiculo };
        cadastro.value = merged;
        console.log('cadastro.value', cadastro.value);
      }
    });

    const cadastrar = () => {
      ApiService.post("veiculos/cadastrar", cadastro.value).then(() => {
        Swal.fire({
          text: "Veículo foi registrado com sucesso!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, proximo!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(() => {
          closeModal.value?.click();
        });
      })
       .catch(({ response }) => {
        Swal.fire({
          text: response.data,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tente de novo!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });
      })
    };

    const atualizar = () => {
      ApiService.post("veiculos/atualizar", cadastro.value).then(() => {
        Swal.fire({
          text: cadastro.value["modelo"] + " foi atualizado com sucesso!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, proximo!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(() => {
          closeModal.value?.click();
        });
      });
    };

    const excluir = () => {
      Swal.fire({
        title: "Deseja realmente excluir esse cliente?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, deletar!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("/veiculos/excluir/" + veiculoProp.id).then(
            () => {
              closeModal.value?.click();
              Swal.fire("Deletado!", "Esse cliente foi excluído", "success");
            }
          );
        }
      });
    };

    //   Swal.fire({
    //     text: "Deseja realmente excluir esse cliente?",
    //     icon: "warning",
    //     buttonsStyling: false,
    //     confirmButtonText: "Sim, excluir!",
    //     customClass: {
    //       confirmButton: "btn fw-bold btn-light-primary"
    //     }
    //   }).then(() => {
    //     console.log('excluiu')
    //     // ApiService.delete("/clientes/excluir/" + clienteProp.cliente.id).then(
    //     //   ({ data }) => {
    //     //     closeModal.value?.click();
    //     //   }
    //     // );
    //   });
    // };

    return {
      cadastrar,
      atualizar,
      cadastro,
      modal,
      closeModal,
      isCadastroNovo,
      validacoes,
      form,
      Form,
      Field,
      excluir,
    };
  },
});

import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { className: "row" }
const _hoisted_2 = { className: "col-lg-6 mb-20" }
const _hoisted_3 = { className: "col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ClientesList = _resolveComponent("ClientesList")
  const _component_LaudosList = _resolveComponent("LaudosList")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_ClientesList, { clientes: _ctx.clientes }, null, 8, ["clientes"])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode(_component_LaudosList, { laudos: _ctx.laudos }, null, 8, ["laudos"])
    ])
  ]))
}
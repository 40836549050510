
import { defineComponent } from "vue";
import { RouterLink } from "vue-router";

export default defineComponent({
  name: "ClientesList",
  components: { RouterLink },
  props: {
    laudos: Array
  },
  setup() {
    return {
      RouterLink
    };
  }
});


import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import VeiculoCadastro from "@/views/pages/widgets/VeiculoCadastro.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { pt } from "yup-locale-pt";

export default defineComponent({
  name: "ClienteCadastro",
  components: { Field, Form, VeiculoCadastro },
  props: {
    cliente: Object,
  },
  setup(props) {
    const valoresIniciais = {
      nome: null,
      id: null,
      cpf: null,
      email: null,
      dataDeNascimento: null
    }
    const cadastro = ref<any>(JSON.parse(JSON.stringify(valoresIniciais)));
    const clienteProp = reactive<any>(props);
    const modal = ref<HTMLElement | null>(null);
    const closeModal = ref<HTMLButtonElement | null>(null);
    const isCadastroNovo = ref(true);
    const form = ref<HTMLFormElement>();
    const veiculo = ref<unknown>({});
    const route = useRouter();

    Yup.setLocale(pt);
    const validacoes = Yup.object().shape({
      formNome: Yup.string().nullable().required().label("Nome"),
      formCpf: Yup.string().nullable().required().label("CPF"),
      formEmail: Yup.string().nullable().required().email().label("Email"),
      formDtnascimento: Yup.date()
        .required()
        .nullable()
        .label("Data de nascimento")
        .default(function () {
          return new Date();
        })
    });

    onMounted(() => {
      modal.value?.addEventListener("hidden.bs.modal", () => {
        isCadastroNovo.value = true;
        cadastro.value = JSON.parse(JSON.stringify(valoresIniciais));
        form.value?.resetForm();
      });
    });

    watch(clienteProp, () => {
      isCadastroNovo.value = false;
      const cliente = clienteProp.cliente;
      const merged = { ...cadastro.value, ...cliente };
      cadastro.value = merged;
      cadastro.value["dataDeNascimento"] = cliente.dataDeNascimento.substring(
        0,
        10
      );
    });

    const cadastrar = () => {
      ApiService.post("clientes/cadastrar", cadastro.value).then(({data}) => {
        cadastro.value.id = data;
        Swal.fire({
          text: "Cliente foi registrado com sucesso!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, proximo!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        });
      })
       .catch(({ response }) => {
        Swal.fire({
          text: response.data,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tente de novo!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger"
          }
        });
      })
    };

    const atualizar = () => {
      ApiService.post("clientes/atualizar", cadastro.value).then(() => {
        Swal.fire({
          text: cadastro.value["nome"] + " foi atualizado com sucesso!",
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, proximo!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary",
          },
        }).then(() => {
          closeModal.value?.click();
        });
      });
    };

    const excluir = () => {
      Swal.fire({
        title: "Deseja realmente excluir esse cliente?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, deletar!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("/clientes/excluir/" + clienteProp.cliente.id).then(
            () => {
              closeModal.value?.click();
              Swal.fire("Deletado!", "Esse cliente foi excluído", "success");
            }
          );
        }
      });
    };

    function setVeiculo(v) {
      veiculo.value = v;
    }

    return {
      cadastrar,
      atualizar,
      cadastro,
      modal,
      closeModal,
      isCadastroNovo,
      validacoes,
      form,
      Form,
      Field,
      excluir,
      veiculo,
      setVeiculo
    };
  }
});
